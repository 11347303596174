<template>
    <v-card class="no-shadow" elevation="0" style="top: 20px;">
        <v-row justify="center">
            <v-col cols="12" md="6">
                <v-card class="pb-6 pr-2 border-radius-1" outlined :loading="loading">
                    <v-row justify="space-between" class="spacer mx-1">
                        <v-col cols="6" xl="8">
                            <p class="text-subtitle-2 mb-0 text-xl-h6 text--primary">
                                {{ chartTitle }}
                            </p>
                        </v-col>
                        <v-col cols="5" xl="4" class="grey--text text-subtitle-2 text-xl-body-1">
                            <p class="mt-xl-1 mb-0 text-right font-weight-black">
                                {{ chartRange }}
                            </p>
                        </v-col>
                    </v-row>
                    <v-card class="no-shadow" id="elem_active_users">
                        <v-row>
                            <v-col
                                v-resize="onResize"
                                id="chart"
                                cols="12"
                                class="graphs mt-4 mt-lg-0"
                                v-show="chartReady"
                            />
                        </v-row>
                    </v-card>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="pb-6 pr-2 border-radius-1" outlined :loading="loading">
                    <v-row justify="space-around" class="spacer mx-1 mb-4">
                        <v-col cols="7">
                            <span class="text-subtitle-2 text-xl-h6 text--primary">
                                {{ $t('stats.inactive_users') }}
                            </span>
                        </v-col>
                        <v-col cols="5" class="px-0 pt-3 pt-xl-4">
                            <span
                                class="text-subtitle-2 text-xl-body-1 grey--text font-weight-black"
                            >
                                {{ $t('stats.last_login') }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-card class="mx-auto overflow-y-auto no-shadow" max-height="250">
                        <v-list disabled>
                            <v-list-item v-for="(item, i) in inactiveUsers" :key="i">
                                <v-row align="center" class="px-2">
                                    <v-col cols="1" class="pa-0 ma-0">
                                        <v-img
                                            :src="`https://assets.beereaders.com/avatar/${item.avatar.name}/${item.avatar.value}`"
                                            max-height="30"
                                            max-width="30"
                                        />
                                    </v-col>
                                    <v-col cols="6" class="pa-0 ma-0">
                                        <v-list-item-title
                                            class="text-body-2 text-capitalize users-name-color font-weight-black"
                                        >
                                            {{ item.name | capitalize }}
                                        </v-list-item-title>
                                    </v-col>
                                    <v-col cols="5" class="pl-1 pr-0 py-0 text-body-2 grey--text">
                                        {{
                                            item.lastLogin
                                                | date({ date: true, location: $i18n.locale })
                                        }}
                                    </v-col>
                                </v-row>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
const echarts = require('echarts')

const xZero = ['1', '2', '3', '4', '5', '6', '7']
const yZero = [0, 0, 0, 0, 0, 0, 0]

export default {
    props: {
        data: { type: Number, default: 0 },
        loading: { type: Boolean, default: true },
        quickStats: { type: Array, default: () => [] },
        range: { type: Number, default: 2 },
    },
    data() {
        return {
            chartRange: '',
            chart: {},
            chartReady: false,
            inactiveUsers: [],
            selectedItem: 0,
            xData: xZero,
            yActiveUsers: yZero,
            yAverageQuizScores: yZero,
            yReadBooks: yZero,
            yReadingHours: yZero,
        }
    },
    mounted() {
        this.chart = echarts.init(document.getElementById('chart'))
        this.changeRank()
    },
    computed: {
        chartTitle() {
            switch (this.data) {
                case 0:
                    return this.$t('stats.stats[0]')
                case 1:
                    return this.$t('stats.stats[1]')
                case 2:
                    return this.$t('stats.stats[2]')
                default:
                    return this.$t('stats.stats[3]')
            }
        },
    },
    methods: {
        changeRank() {
            this.setBarChart()
            this.chartReady = true
        },
        getColors() {
            let colors = ['#F9C047', '#FCD684', '#FFE4AC']
            switch (this.data) {
                case 0:
                    colors = ['#524874', '#887CB0', '#A396CF']
                    break
                case 1:
                    colors = ['#39B4CA', '#7CDEED', '#9FF3FF']
                    break
                case 2:
                    colors = ['#1CCFC2', '#67E6DD', '#96F5EE']
                    break
                default:
                    break
            }
            return colors
        },
        getData() {
            const data = { x: this.xData, y: 0 }
            switch (this.data) {
                case 0:
                    data.y = this.yActiveUsers
                    break
                case 1:
                    data.y = this.yReadingHours
                    break
                case 2:
                    data.y = this.yReadBooks
                    break
                case 3:
                    data.y = this.yAverageQuizScores
                    break
                default:
                    break
            }
            return data
        },
        getOption() {
            const colors = this.getColors()
            const data = this.getData()
            let min = null
            let max = null
            if (this.data === 0 || this.data === 3) {
                min = 0
                max = 100
            }
            return {
                grid: {
                    left: '12%',
                    right: '10%',
                    bottom: '10%',
                    top: '10%',
                },
                xAxis: {
                    type: 'category',
                    data: data.x,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    minInterval: 1,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dotted',
                            width: 2,
                        },
                    },
                    min,
                    max,
                    axisLabel: {
                        formatter: (value) => {
                            let result = value
                            if (this.data === 0 || this.data === 3) {
                                result = `${value}%`
                            }
                            return result
                        },
                    },
                },
                series: [
                    {
                        data: data.y,
                        type: 'bar',
                        barWidth: 30,
                        itemStyle: {
                            barBorderRadius: [10, 10, 0, 0],
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: colors[0],
                                    },
                                    {
                                        offset: 0.5,
                                        color: colors[1],
                                    },
                                    {
                                        offset: 1,
                                        color: colors[2],
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                ],
            }
        },
        itsLeapYear(year) {
            return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
        },
        mapCountPercent(array, isCount) {
            const result = []
            if (isCount) {
                array.forEach((element) => {
                    result.push(element.count)
                })
            } else {
                array.forEach((element) => {
                    result.push(element.percent)
                })
            }
            return result
        },
        mapDate(array) {
            const result = []
            array.forEach((element) => {
                result.push(element.day)
            })
            return result
        },
        mapUsers(array) {
            const result = []
            array.forEach((element) => {
                result.push({
                    name: `${element.firstname.split(' ')[0]} ${element.lastname}`,
                    lastLogin: element.lastLoginTime,
                    avatar: {
                        name: element.avatar.name,
                        value: element.avatar.value,
                    },
                })
            })
            return result
        },
        onResize() {
            if (this.chartReady) {
                this.chart.resize()
            }
        },
        reloadData() {
            if (this.quickStats && this.quickStats.length > 0) {
                const data = this.quickStats[this.range]
                this.chartRange = data.title
                this.inactiveUsers = this.mapUsers(data.inactiveReaders)
                this.xData = this.mapDate(data.activeReadersInterval)
                this.yActiveUsers = this.mapCountPercent(data.activeReadersInterval, false)
                this.yAverageQuizScores = this.mapCountPercent(data.avgQuizInterval, false)
                this.yReadBooks = this.mapCountPercent(data.readsInterval, true)
                this.yReadingHours = this.mapCountPercent(data.readingHoursInterval, true)
            }
            this.changeRank()
        },
        setBarChart() {
            this.chart.setOption(this.getOption())
            setTimeout(() => {
                this.onResize()
            }, 100)
        },
    },
    watch: {
        data() {
            this.reloadData()
        },
        quickStats() {
            this.reloadData()
        },
        range() {
            this.reloadData()
        },
    },
}
</script>

<style scoped>
.graphs {
    width: 100px;
    height: 251px;
}
.no-shadow {
    box-shadow: 0 0 0 0 hsl(0, 0%, 44%) !important;
}
.spacer {
    border-bottom: 2px dotted #cac4db;
}
.users-name-color {
    color: #979797;
}
</style>
